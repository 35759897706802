import { Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Image, Text } from "@chakra-ui/react";
import PageTransition from "../components/PageTransition";
import SpecList from "../components/SpecList";

export default function MainPage() {
  return (
    <PageTransition>
      <Box paddingX="6" mt="2rem">
        <HStack spacing="2rem">
          <Box display={{ md: "flex" }}>
            <Image borderRadius="full" boxSize="100px" src="me.webp" />
          </Box>
          <Box>
            <Text>Hi, I'm Oscar Andersson!</Text>
          </Box>
        </HStack>

        <Tabs variant="enclosed" size="md" align="center">
          <TabList>
            <Tab>Software engineering</Tab>
            <Tab>Management skill</Tab>
            <Tab>Hobbies</Tab>
            <Tab>FAQ</Tab>
          </TabList>

          <TabPanels textAlign="left" mt={8}>
            <TabPanel>
              <Text>I've done some stuff.</Text>
              <br />
              <Text>hjdskla hjds hak dhljskal hdjskal hdjska.</Text>
              <br />
              <Text>y dhsuahjds hjdsfkla hjdskal hjks lhjsklhsjak hsjakl.</Text>
            </TabPanel>
            <TabPanel>
              <Text>I've managed some stuff.</Text>
              <br />
              <Text>fdshjkalh fdsjahjfkdsl ahjkfldsa.</Text>
              <br />
              <Text>gsagsagsag.</Text>
            </TabPanel>
            <TabPanel>
              <Text>I have some hobbies.</Text>
              <br />
              <Text>jdsköa fhjsd hajfkl hajkfd.</Text>
              <br />
              <Text>jhfkds jhafds hajl fsak.</Text>
            </TabPanel>
            <TabPanel>
              <SpecList
                title=""
                caption="Frequently asked questions for Oscar"
                headingNames={{ key: "Question", value: "Answer" }}
                specs={[
                  { key: "Du gillar FETA?", value: "Jag älskar FETA!" },
                  { key: "Vad fan mäter dom?", value: "Halvmetern minst" },
                ]}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </PageTransition>
  );
}
