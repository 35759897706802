import { StackDivider, VStack } from "@chakra-ui/react";
import PageTransition from "../components/PageTransition";
import Post from "../components/Post";

const verticalStackMargin = 16;

export default function AboutPage() {
  return (
    <PageTransition>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        align="stretch"
        spacing={verticalStackMargin}
        mt={verticalStackMargin}
        mb={verticalStackMargin}
      >
        <Post
          heading="This is a placeholder"
          paragraphs={[`About Oscar blablablablablcb abl ablab labl bla b labla.`]}
        />
      </VStack>
    </PageTransition>
  );
}
