import React from "react";
import { SlideFade } from "@chakra-ui/react";

interface Props {
  children: React.ReactNode;
}

const PageTransition = ({ children }: Props) => {
  return <SlideFade in>{children}</SlideFade>;
};

export default PageTransition;
