import { Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer, Heading, Box } from "@chakra-ui/react";

interface SpecListProps {
  title: string;
  headingNames: { key: string; value: string };
  specs: { key: string; value: string }[];
  caption: string;
}

const SpecList = ({ title, headingNames, specs, caption }: SpecListProps) => {
  return (
    <>
      <Box>
        <Heading as="h2" color="black" size="xl" fontWeight={400}>
          {title}
        </Heading>
      </Box>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>{caption}</TableCaption>
          <Thead>
            <Tr>
              <Th>{headingNames.key}</Th>
              <Th>{headingNames.value}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {specs.map(({ key, value }, index) => (
              <Tr key={index}>
                <Td>{key}</Td>
                <Td>{value}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SpecList;
