import { Box, Heading, Text } from "@chakra-ui/react";

interface PostProps {
  heading: string;
  paragraphs: string[];
}

const Post = ({ heading, paragraphs }: PostProps) => {
  return (
    <Box>
      <Box>
        <Heading as="h2" color="black" size="xl" fontWeight={400}>
          {heading}
        </Heading>
      </Box>
      {paragraphs.map((text, index) => (
        <Box mt="4" key={index}>
          <Text fontSize="medium">{text}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default Post;
