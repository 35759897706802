import { StackDivider, VStack } from "@chakra-ui/react";
import PageTransition from "../components/PageTransition";
import Post from "../components/Post";

const verticalStackMargin = 16;

export default function ProjectsPage() {
  return (
    <PageTransition>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        align="stretch"
        spacing={verticalStackMargin}
        mt={verticalStackMargin}
        mb={verticalStackMargin}
      >
        <Post
          heading="This is a placeholder"
          paragraphs={[
            `This is some text box that have the purpose of filling up space.
          Bla bla bla blah.
          Blo blo.`,
            `This type of box may have multiple paragraphs.`,
          ]}
        />
        <Post
          heading="Yet another placeholder"
          paragraphs={[
            `Another placeholder, with the same style as previous text box.
          There is only one paragraph this time however.`,
          ]}
        />
      </VStack>
    </PageTransition>
  );
}
